<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <NoticeWriteForm v-if="pageData" :page-data="pageData" />
    </Layout>
  </div>
</template>

<script>
import Vue from 'vue';
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header.vue';
import NoticeWriteForm from '../../../components/notice/NoticeWriteForm.vue';

export default Vue.extend({
  components: {
    Layout,
    PageHeader,
    NoticeWriteForm
  },
  async created() {
    await this.$store
      .dispatch('notice/getNoticeInfo', {
        noticeId: this.$route.params.idx
      })
      .then(res => {
        this.responseData = res.data.data;
        console.log(this.responseData);
      });
  },
  computed: {
    pageData() {
      return this.responseData;
    }
  },
  data() {
    return {
      title: 'Notice Modify',
      items: [
        {
          text: 'Sucoding',
          href: '/'
        },
        {
          text: 'Course',
          href: '/'
        },
        {
          text: 'Notice Modify',
          active: true
        }
      ],
      responseData: null
    };
  }
});
</script>
