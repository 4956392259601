<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">기본정보</h4>
          <form action="#" @submit.prevent="typeForm">
            <!-- row -->
            <div class="row">
              <!-- col -->
              <div class="col-md-4 col-sm-4">
                <div class="form-group mb-4">
                  <label>게시타입</label>
                  <select
                    v-model="typeform.noticeType"
                    class="form-control mg-r-5"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.noticeType.$error
                    }"
                  >
                    <option value="notice" selected>공지사항</option>
                    <option value="event" selected>이벤트</option>
                  </select>
                  <div v-if="typesubmit && $v.typeform.noticeType.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.noticeType.required">값을 선택해주세요.</span>
                  </div>
                </div>
              </div>
              <!-- col -->
              <div class="col-md-4 col-sm-4">
                <div class="form-group mb-4">
                  <label>코스</label>
                  <select v-if="courseData" v-model="typeform.courseId" class="form-control mg-r-5">
                    <option v-for="item in courseData" :key="item.idx" :value="item.idx">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- col -->
              <div class="col-md-4 col-sm-4">
                <div class="form-group mb-4">
                  <label>순서</label>
                  <input
                    v-model="typeform.orderNum"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.orderNum.$error
                    }"
                  />
                  <div v-if="typesubmit && $v.typeform.orderNum.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.orderNum.required">값을 입력해주세요.</span>
                    <span v-if="!$v.typeform.orderNum.numeric">숫자만 입력해주세요.</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- row -->
            <div class="row">
              <!-- col -->
              <div class="col-md-4 col-sm-4">
                <div class="form-group mb-4">
                  <label>오픈여부</label>
                  <select
                    v-model="typeform.openYn"
                    class="form-control mg-r-5"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.openYn.$error
                    }"
                  >
                    <option value="Y" selected>Y</option>
                    <option value="N">N</option>
                  </select>
                  <div v-if="typesubmit && $v.typeform.openYn.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.openYn.required">값을 선택해주세요.</span>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="form-group mb-4">
                  <label>링크</label>
                  <input v-model="typeform.linkSrc" type="text" class="form-control" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group mb-4">
                  <label>제목</label>
                  <input
                    v-model="typeform.title"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.title.$error
                    }"
                  />
                  <div v-if="typesubmit && $v.typeform.title.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.title.required">값을 입력해주세요.</span>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-4">
                  <label>상세 내용</label>
                  <CkeditorNuxtBasic v-model="typeform.content" :config="config" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group mb-0">
                  <div>
                    <button type="submit" class="btn btn-primary mr-2">
                      {{ typeform.noticeId === '' ? '등록' : '수정' }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary m-l-5 ml-1"
                      @click="$router.go(-1)"
                    >
                      취소
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {validationMixin} from 'vuelidate';
import {required, numeric} from 'vuelidate/lib/validators';
import CkeditorNuxtBasic from '@/components/editor/CkeditorNuxtBasic.vue';

import he from 'he/he/';
export default Vue.extend({
  components: {
    CkeditorNuxtBasic
  },
  mixins: [validationMixin],
  props: {
    pageData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      typeform: {
        noticeId: '',
        courseId: '',
        noticeType: '',
        orderNum: '',
        linkSrc: '',
        title: '',
        content: ''
      },
      questionItem: [], // 오지선다 문제
      quizQuestionmodifyFlag: false, // 퀴즈 문제가 수정이 되면 전부 삭제하고 재 등록해야해서 플래그로 관리한다
      typesubmit: false,
      config: {
        codeBlock: {
          languages: [
            {language: 'css', label: 'CSS'},
            {language: 'html', label: 'HTML'},
            {language: 'javascript', label: 'JavaScript'},
            {language: 'python', label: 'Python'}
          ]
        },
        link: {
          // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
          addTargetToExternalLinks: true,

          // Let the users control the "download" attribute of each link.
          decorators: [
            {
              mode: 'manual',
              label: 'Downloadable',
              attributes: {
                download: 'download'
              }
            }
          ]
        },
        image: {
          resizeUnit: '%',
          resizeOptions: [
            {
              name: 'resizeImage:original',
              value: null
            },
            {
              name: 'resizeImage:25',
              value: '25'
            },
            {
              name: 'resizeImage:50',
              value: '50'
            },
            {
              name: 'resizeImage:75',
              value: '75'
            }
          ],
          toolbar: [
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'toggleImageCaption',
            'imageTextAlternative',
            'resizeImage'
          ]
        }
      },
      courseData: null
    };
  },
  async created() {
    await this.$store
      .dispatch('course/getCourseList', {
        page: 1,
        perPage: 10,
        order: 'asc'
      })
      .then(res => {
        this.courseData = res.data.list;
      });
  },
  mounted() {
    if (this.pageData.length !== 0 && Object.keys(this.pageData).length > 0) {
      this.typeform.noticeId = this.pageData.idx;
      this.typeform.courseId = this.pageData.cIdx;
      this.typeform.noticeType = this.pageData.type;
      this.typeform.orderNum = this.pageData.orderNum;
      this.typeform.openYn = this.pageData.openYn;
      this.typeform.title = this.pageData.title;

      setTimeout(() => {
        this.typeform.content = this.pageData.content;
      }, 100);
    }
  },
  validations: {
    typeform: {
      noticeType: {required},
      orderNum: {required, numeric},
      openYn: {required},
      title: {required},
      content: {required}
    }
  },
  methods: {
    encode(value) {
      return he.encode(value);
    },
    decode(value) {
      return he.decode(value);
    },
    async typeForm() {
      this.typesubmit = true;
      this.$v.typeform.$touch();

      if (!this.$v.$invalid) {
        await this.$store
          .dispatch('notice/addNotice', {
            noticeId: this.typeform.noticeId,
            courseId: this.typeform.courseId,
            noticeType: this.typeform.noticeType,
            orderNum: this.typeform.orderNum,
            openYn: this.typeform.openYn,
            title: this.typeform.title,
            content: this.typeform.content
          })
          .then(response => {
            if (response.data.result) {
              alert(response.data.message);
            } else {
              alert(response.data.message);
            }
          });
      }
    },
    editorInit: function(editor) {
      require('brace/ext/language_tools'); //language extension prerequsite...
      require('brace/mode/html');
      require('brace/mode/javascript'); //language
      require('brace/mode/less');
      require('brace/theme/monokai');
      require('brace/snippets/javascript'); //snippet
      require(['emmet/emmet'], function(data) {
        window.emmet = data.emmet;
      });
      editor.setOption('enableEmmet', true);
      editor.getSession().setUseWrapMode(true);
      editor.setOptions({
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        fontSize: 14,
        highlightActiveLine: true,
        showLineNumbers: true,
        tabSize: 2,
        showPrintMargin: false,
        showGutter: true
      });
    }
  }
});
</script>
<style lang="scss" scoped>
.q-label {
  display: block;
  overflow: auto;
  line-height: 28px;

  button {
    float: right;
  }
}
ul,
ol,
li {
  padding: 0;
  list-style: none;
}

.question li {
  display: grid;
  grid-template-columns: 20px 1fr 1fr 100px 40px;
  row-gap: 10px;
  column-gap: 10px;
  padding: 10px 0;
  label {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }
}
.mr-2 {
  margin-right: 10px;
}
</style>
