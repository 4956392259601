var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title mb-4"},[_vm._v("기본정보")]),_c('form',{attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();return _vm.typeForm.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 col-sm-4"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("게시타입")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.noticeType),expression:"typeform.noticeType"}],staticClass:"form-control mg-r-5",class:{
                    'is-invalid': _vm.typesubmit && _vm.$v.typeform.noticeType.$error
                  },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.typeform, "noticeType", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"notice","selected":""}},[_vm._v("공지사항")]),_c('option',{attrs:{"value":"event","selected":""}},[_vm._v("이벤트")])]),(_vm.typesubmit && _vm.$v.typeform.noticeType.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.noticeType.required)?_c('span',[_vm._v("값을 선택해주세요.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-4 col-sm-4"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("코스")]),(_vm.courseData)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.courseId),expression:"typeform.courseId"}],staticClass:"form-control mg-r-5",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.typeform, "courseId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.courseData),function(item){return _c('option',{key:item.idx,domProps:{"value":item.idx}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_vm._e()])]),_c('div',{staticClass:"col-md-4 col-sm-4"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("순서")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.orderNum),expression:"typeform.orderNum"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.typesubmit && _vm.$v.typeform.orderNum.$error
                  },attrs:{"type":"text"},domProps:{"value":(_vm.typeform.orderNum)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.typeform, "orderNum", $event.target.value)}}}),(_vm.typesubmit && _vm.$v.typeform.orderNum.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.orderNum.required)?_c('span',[_vm._v("값을 입력해주세요.")]):_vm._e(),(!_vm.$v.typeform.orderNum.numeric)?_c('span',[_vm._v("숫자만 입력해주세요.")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 col-sm-4"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("오픈여부")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.openYn),expression:"typeform.openYn"}],staticClass:"form-control mg-r-5",class:{
                    'is-invalid': _vm.typesubmit && _vm.$v.typeform.openYn.$error
                  },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.typeform, "openYn", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"Y","selected":""}},[_vm._v("Y")]),_c('option',{attrs:{"value":"N"}},[_vm._v("N")])]),(_vm.typesubmit && _vm.$v.typeform.openYn.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.openYn.required)?_c('span',[_vm._v("값을 선택해주세요.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("링크")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.linkSrc),expression:"typeform.linkSrc"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.typeform.linkSrc)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.typeform, "linkSrc", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("제목")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.title),expression:"typeform.title"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.typesubmit && _vm.$v.typeform.title.$error
                  },attrs:{"type":"text"},domProps:{"value":(_vm.typeform.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.typeform, "title", $event.target.value)}}}),(_vm.typesubmit && _vm.$v.typeform.title.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.title.required)?_c('span',[_vm._v("값을 입력해주세요.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("상세 내용")]),_c('CkeditorNuxtBasic',{attrs:{"config":_vm.config},model:{value:(_vm.typeform.content),callback:function ($$v) {_vm.$set(_vm.typeform, "content", $$v)},expression:"typeform.content"}})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group mb-0"},[_c('div',[_c('button',{staticClass:"btn btn-primary mr-2",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.typeform.noticeId === '' ? '등록' : '수정')+" ")]),_c('button',{staticClass:"btn btn-secondary m-l-5 ml-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" 취소 ")])])])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }